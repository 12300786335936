import React from 'react'
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'

const ProductSubAshienro = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid">
                    <div className="container mt-4  pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around align-items-center ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductSub1.png")} alt='works-at-iclodsoft' />
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-4 pb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "24px", color: "#151515", letterSpacing: "1px" }} > ASHIENRO 50 Tablet </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    COMPOSITION:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Each tablet contains: </p>
                                        <p> Enrofloxacin………………………….50 mg </p>
                                        <p> Excipients……………………………………q.s. </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INDICATIONS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            ASHIENRO 50 is indicated for the treatment of respiratory, reproductive, urinary tract infections, gastrointestinal tract infections, skin and soft tissue infections. It is also effective in secondary bacterial complications of viral diseases such as distemper, gastroenteritis (parvo virus) etc.
                                        </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    DOSAGE AND ADMINISTRATION:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Dogs and Cats: </p>
                                        <p> For oral administration </p>
                                        <p> 5 mg per kg body weight once daily for 3-5 days. </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    PRESENTATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Pack of 50, 100, 500 Tablets </p>
                                        <span className='' style={{ fontSize: "1rem", fontWeight: "600" }}> Category: <NavLink className='text-decoration-none' to='/product'><span className='' style={{ color: "#888", fontSize: '12.5px', fontWeight: "600" }} > COMPANION ANIMALS </span></NavLink> </span>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductSubAshienro