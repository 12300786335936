import React from 'react'
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'

const ProductSubDurotic = () => {
    return (
        <>

            <Navbar /> 

            <section>
                <div className="container-fluid">
                    <div className="container mt-4  pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around align-items-center ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductSub5.png")} alt='works-at-iclodsoft' />
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-4 pb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "24px", color: "#151515", letterSpacing: "1px" }} > DUROTIC Injection </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    COMPOSITION:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Each ml contains: </p>
                                        <p> Furosemide ……………………………………………50 mg </p>
                                        <p> Water for Injection ………………………………………q.s. </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INDICATIONS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Dogs, Cats & Horse:
                                        </p>
                                        <p>
                                            Removal of abnormally retained extracelluar fluid.
                                        </p>
                                        <p>
                                            Edematous conditions like pulmonary edema, ascitis associated with cardiac insufficiency and tissue edema.
                                        </p>
                                        <p>
                                            In racing horses it helps to prevent bleeding from lungs (exercise – induced pulmonary hemorrhage)
                                        </p>
                                        <p>
                                            Cattle:
                                        </p>
                                        <p>
                                            Post parturient udder edema.
                                        </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    DOSAGE AND ADMINISTRATION:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> By Intramuscular (IM) or Intravenous (IV) only. </p>
                                        <p> Dogs and Cats: </p>
                                        <p>
                                            2 mg- 5 mg/kg body weight once daily or twice at 6 – 8 hr interval (0.5 ml -1 ml for 10 kg body weight once daily or twice at 6-8 hrs interval)
                                        </p>
                                        <p>
                                            Cattle and Horses:
                                        </p>
                                        <p>
                                            0.5 mg – 1 mg/kg body weight once daily or twice at 6 – 8 hr interval (5 -10 ml once or twice daily at 6 – 8 hr interval) until desired results are achieved. In case of cattle, treatment not to exceed 48 hrs post-calving.
                                        </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    PRESENTATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p> 10 ml, 50 ml & 100 ml Vial. </p>
                                        <span className='' style={{ fontSize: "1rem", fontWeight: "600" }}> Category: <NavLink className='text-decoration-none' to='/product'><span className='' style={{ color: "#888", fontSize: '12.5px', fontWeight: "600" }} > COMPANION ANIMALS </span></NavLink> </span>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductSubDurotic