import React from 'react'
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'

const ProductSubAshimedin = () => {
    return (
        <>

            <Navbar /> 

            <section>
                <div className="container-fluid">
                    <div className="container mt-4  pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around align-items-center ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductSub2.png")} alt='works-at-iclodsoft' />
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-4 pb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "24px", color: "#151515", letterSpacing: "1px" }} > ASHIMEDIN 5 Tablet </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    COMPOSITION:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Each chewable tablet contains: </p>
                                        <p> Pimobendan ………………5.0 mg </p>
                                        <p> Excipients……………………………………q.s. </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INDICATIONS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            ASHIMEDIN 5 mg is indicated for the treatment of dilative myocarditis & congestive cardiac insufficiency caused by an insufficiency of heart valves in dogs.
                                        </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    DOSAGE AND ADMINISTRATION:
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            The recommended optimum dose per day is 0.5 mg/kg (1 tablet / 10 kg as Ashimedin® tablet 5.0 mg), which is divided into 2 times application per day (0.25 mg/kg/application), thus administer 1/2  of the recommended optimum dose per day in the morning time and the rest of 1/2 after 12 hours.
                                        </p>

                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    PRESENTATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Pack of 50 &100 Tablets </p>
                                        <span className='' style={{ fontSize: "1rem", fontWeight: "600" }}> Category: <NavLink className='text-decoration-none' to='/product'><span className='' style={{ color: "#888", fontSize: '12.5px', fontWeight: "600" }} > COMPANION ANIMALS </span></NavLink> </span>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default ProductSubAshimedin