import React from 'react'
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'

const ProductSubDiroheart = () => {
    return (
        <>

            <Navbar />  

            <section>
                <div className="container-fluid">
                    <div className="container mt-4  pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around align-items-center ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductSub4.png")} alt='works-at-iclodsoft' />
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-4 pb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "24px", color: "#151515", letterSpacing: "1px" }} > DIROHEART Tablet </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    COMPOSITION:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Ivermectin ……..……………………..………..34.0 mcg </p>
                                        <p> Equivalent to Pyrantel USP……………….28.5 mg </p>
                                        <p> Excipients……………………………………q.s. </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INDICATIONS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            For use in dogs to prevent canine heartworm disease by eliminating the tissue stage of heartworm larvae (Dirofilariaimmitis) for a month (30 days) after infection and for the treatment and control of ascarids (Toxocaracanis, Toxascarisleonina) and hookworms (Ancylostomacaninum, Uncinariastenocephala, Ancylostomabraziliense).
                                        </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    DOSAGE AND ADMINISTRATION:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> DIROHEART is recommended for dogs 6 weeks of age and older. </p>
                                        <p> For dogs over 45 kgs the appropriate combination of these tablets must be given. </p>
                                        <p>
                                            DIROHEART (ivermectin/pyrantel) tablets should be administered orally at monthly intervals at the recommended minimum doselevel of 6 mcg of ivermectin per kilogram (2.72 mcg/lb) and 5 mg of pyrantel (as pamoate salt) per kg (2.27 mg/lb) of body weight.
                                        </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    PRESENTATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p> DIROHEART-SS………………………………..Pack of 10 chewable tablets </p>
                                        <p> DIROHEART-S/M/L………………………………..Pack of 6 chewable tablets & Packs of 12 chewable tablets </p>
                                        <span className='' style={{ fontSize: "1rem", fontWeight: "600" }}> Category: <NavLink className='text-decoration-none' to='/product'><span className='' style={{ color: "#888", fontSize: '12.5px', fontWeight: "600" }} > COMPANION ANIMALS </span></NavLink> </span>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductSubDiroheart