import React from 'react'
import "./Silder.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Silder = () => {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1428 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 1428, min: 997 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 997, min: 767 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
        },

    };

    return (
        <>


            <div className="container mt-4 mb-5 pb-4"  >
                <div className="row  d-flex justify-content-center align-items-center text-center laila-semibold  mt-5">
                    <div className='mb-4 pb-lg-4'>
                        <h4 className='text-center  laila-semibold ' style={{ fontWeight: "600", letterSpacing: "2px" }} >  Approved by </h4>
                        <h2 class="hr-lines">
                            <i class="fa-solid fa-star" style={{ color: "#048AB2" }} ></i>
                        </h2>
                    </div>
                    <Carousel responsive={responsive}
                        autoPlay={true}
                        autoPlaySpeed={2000}>

                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSize img-fluid mx-auto d-block"
                                    src={require("../img//Silder1.png")}
                                    alt=""
                                />
                                <h3> EU GMP </h3>
                            </div>
                        </div>

                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSize img-fluid  text-center"
                                    src={require("../img/Silder2.png")}
                                    alt=""
                                />
                                <h3> Zimbabwe </h3>
                            </div>
                        </div>

                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSize img-fluid  text-center"
                                    src={require("../img/Silder3.png")}
                                    alt=""
                                />
                                <h3> WHO GMP </h3>
                            </div>
                        </div>



                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSize img-fluid  text-center"
                                    src={require("../img/Silder4.png")}
                                    alt=""
                                />
                                <h3> Saudi Food & Drug Authority </h3>
                            </div>
                        </div>

                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSize img-fluid mx-auto d-block"
                                    src={require("../img/Silder5.png")}
                                    alt=""
                                />
                                <h3> Nigeria </h3>
                            </div>
                        </div>

                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSize img-fluid  text-center"
                                    src={require("../img/Silder6.png")}
                                    alt=""
                                />
                                <h3> Tanzania </h3>
                            </div>
                        </div>



                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSize img-fluid  text-center"
                                    src={require("../img/Silder7.png")}
                                    alt=""
                                />
                                <h3> Malawi </h3>
                            </div>
                        </div>

                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSize img-fluid  text-center"
                                    src={require("../img/Silder8.png")}
                                    alt=""
                                />
                                <h3> Kenya </h3>
                            </div>
                        </div>

                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSize img-fluid  text-center"
                                    src={require("../img/Silder9.png")}
                                    alt=""
                                />
                                <h3> Uganda </h3>
                            </div>
                        </div>




                    </Carousel>
                </div>
            </div>


        </>
    )
}

export default Silder