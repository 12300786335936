import React from 'react'
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'

const ProductSubAshiverPlus = () => {
    return (
        <>

            <Navbar />  

            <section>
                <div className="container-fluid">
                    <div className="container mt-4  pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around align-items-center ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductSub3.png")} alt='works-at-iclodsoft' />
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-4 pb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "24px", color: "#151515", letterSpacing: "1px" }} > ASHIVER PLUS Tablet </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    COMPOSITION:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Ivermectin ……………….……….2 mg </p>
                                        <p> Praziquantel …….……………..50 mg </p>
                                        <p> Excipients……………………………………q.s. </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INDICATIONS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            ASHIVER PLUS is indicated for the treatment and control of heart worm disease caused by Dirofilariaimmitis (3rd and 4th stage larva) and gastrointestinal roundworm infestation (Ancylostomacaninum, Trichurisvulpis,  Toxacaracanis, and Capillariaaerophila), and tapeworm infestation (Dipylidiumcaninum, Taeniapisiformis) in dogs and cats.
                                        </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    DOSAGE AND ADMINISTRATION:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Dogs: 1 tablet for 10 kg body weight. </p>
                                        <p> Cats: ½  tablet for 5 kg body weight. </p>
                                        <p>
                                            In case of Taenia spp. repeat the treatment after 21 days.  As a preventive measure against filariasis the dose should be given in consultation with a veterinarian.
                                        </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    PRESENTATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p> 25  x 4 Tablets &  5 x  4 Tablets </p>
                                        <span className='' style={{ fontSize: "1rem", fontWeight: "600" }}> Category: <NavLink className='text-decoration-none' to='/product'><span className='' style={{ color: "#888", fontSize: '12.5px', fontWeight: "600" }} > COMPANION ANIMALS </span></NavLink> </span>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductSubAshiverPlus