import React from 'react'
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'

const ProductSubFurnil = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid">
                    <div className="container mt-4  pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around align-items-center ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductSub7.png")} alt='works-at-iclodsoft' />
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-4 pb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "24px", color: "#151515", letterSpacing: "1px" }} > FURNIL (Oral Paste) </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    CONTENTS:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Each 5 g contains </p>
                                        <p> (Guaranteed Analysis): </p>
                                        <p> Liquid Paraffin </p>
                                        <p> (Mineral Oil/Petrolatum) ……………. 2 g </p>
                                        <p> Vitamin E (all-rac-Alpha-Tocopheryl </p>
                                        <p> Acetate) ……………………………………. 6 IU </p>
                                        <p> Excipients  …………………………………….q.s. </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    HAIRBALL PROTECTOR FOR CATS & KITTENS
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Helps to prevent and eliminate common hairball in cats by maintaining healthy digestion
                                        </p>
                                        <p>
                                            Keeps cat active and energetic
                                        </p>
                                        <p>
                                            Supports natural cleansing of the body with the help of protein and vitamin
                                        </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    USAGE:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> For elimination of hairballs: </p>
                                        <p> Cat:½tsp orally for 3 consecutive days </p>
                                        <p> Kittens (over 6 weeks): ¼ tsp orally for 3 consecutive days </p>
                                        <p> Prevention: </p>
                                        <p> Cat:½tsp orally for 2-3 times a week </p>
                                        <p> Kittens (over 6 weeks): ¼ tsp orally for 2- 3 times a week </p>
                                        <p> This product is intended for intermittent or supplement feeding only. </p>
                                        <p> &nbsp; </p>

                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    PRESENTATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p> 70 g Tube </p>
                                        <span className='' style={{ fontSize: "1rem", fontWeight: "600" }}> Category: <NavLink className='text-decoration-none' to='/product'><span className='' style={{ color: "#888", fontSize: '12.5px', fontWeight: "600" }} > COMPANION ANIMALS </span></NavLink> </span>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductSubFurnil